import './testimonial.scss';

class Testimonial {

    constructor(element, options) {

        const settings = {
            initAttr: 'data-testimonials', 
            contentClass: '.testimonial__content', 
            resizeTimeout: 150
        };

        this.settings = Object.assign({}, settings, options);

        this.$testimonial = element;

        this.initialize();
    }

    initialize() {

        const $contents = this.$testimonial.querySelectorAll(this.settings.contentClass);
        let resizeTimer = null;

        const calculateContentHeight = () => {
            
            let maxHeight = 0;
            
            for(let i = 0; i < $contents.length; i++) {
                let $content = $contents[i];
                $content.style.height = 'auto';
                if($content.offsetHeight > maxHeight) {
                    maxHeight = $content.offsetHeight;
                }
            }

            for(let i = 0; i < $contents.length; i++) {
                let $content = $contents[i];
                $content.style.height = maxHeight + 'px';
            }

            return maxHeight;
        };

        let contentsMaxHeight = calculateContentHeight();

        import ('../../components/slider/slider').then(( { Slider }) => {
            new Slider(this.$testimonial, {
                effect: 'fade', 
                speed: 0, 
                slidesPerView: 1, 
                slidesPerGroup: 1,
                initAttr: this.settings.initAttr, 
                onInit: () => {
                    this.$testimonial.querySelector('.swiper-controls').style.top = contentsMaxHeight + 'px';
                }, 
                onResize: () => {
                    this.$testimonial.querySelector('.swiper-controls').style.top = contentsMaxHeight + 'px';
                }
            });
        });

        window.addEventListener('resize', () => {
            clearTimeout(resizeTimer);
            resizeTimer = window.setTimeout(() => {
                contentsMaxHeight = calculateContentHeight();
            }, this.settings.resizeTimeout);
        });

        window.addEventListener('orientationchange', () => {
            clearTimeout(resizeTimer);
            resizeTimer = window.setTimeout(() => {
                contentsMaxHeight = calculateContentHeight();
            }, this.settings.resizeTimeout);
        });
    }
}

export { Testimonial };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const context = eventDetails.$context;

    console.log(context);
    if (context) {
        const $$testimonials = context.querySelectorAll('[data-testimonials="root"]');
        for (let i = 0; i < $$testimonials.length; i++) {

            const $testimonials = $$testimonials[i];

            const testimonialsAPI = new Testimonial($testimonials);
            $testimonials.testimonialsAPI = testimonialsAPI;
        }
    }
});
